body {
  display: flex;
  height: 100%;
  flex-direction: column;
}

#root {
  flex: 1 1 0;
}

#logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  color: white;
  font-size: 30px;
  /* background: rgba(255, 255, 255, 0.3); */
}
